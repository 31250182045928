<template>
	<main
		class="order-confirmed container-white"
		:class="{'mobile-container': mobileCheck()}"
	>
		<section class="order-confirmed__container container-base">
			<div class="order-confirmed__content">
				<h2 class="order-confirmed__title">Ваш заказ подтвержден</h2>
				<div class="order-confirmed__info">
					<div class="order-confirmed__info-block">
						<span class="order-confirmed__info-text">Заказ #{{ orderId }}</span>
						<span class="order-confirmed__info-text">{{ deliveryDateTime }}</span>
						<span class="order-confirmed__info-text">Сумма: {{ totalAmount }} руб.</span>
					</div>
					<p class="order-confirmed__info-subtitle">Информация о заказе появится в разделе “Мои заказы”</p>
				</div>
			</div>
			<a
				href="/application/personal"
				class="order-confirmed__btn btn"
			>Перейти в Мои заказы</a>
		</section>
		<BottomNavigation/>
	</main>
</template>
<script>
import BottomNavigation from "@/components/nav/BottomNavigation.vue";
import {mobileCheck} from "@/helpers";
import {notification} from "@/utils";
import {mapActions} from "vuex";

export default {
	name: "OrderConfirmedPage",
	components: {BottomNavigation},
	data()
	{
		return {
			orderDetails: null,
			orderId: null
		}
	},
	computed: {
		deliveryDateTime()
		{
			return this.orderDetails?.order.find(item => item.title === "Дата и время доставки")?.value || '';
		},
		totalAmount()
		{
			return this.orderDetails?.payedSum || 0;
		},
	},
	methods: {
		...mapActions({
			fetchOrderDetails: "order/fetchOrderDetails",
		}),
		mobileCheck,
		async loadOrderDetails()
		{
			try
			{
				const orderId = this.$route.params.id;
				const data = await this.fetchOrderDetails(orderId);

				if (!data) await this.$router.push('/');

				this.orderId = orderId;
				this.orderDetails = data;
			} catch (error)
			{
				notification({title: 'Произошла ошибка при получении информации о заказе', type: 'error'});
			}
		}
	},
	async mounted()
	{
		await this.loadOrderDetails()
	}
}
</script>
<style lang="scss">
.order-confirmed
{
	padding: 60px 0;

	&__container
	{
		box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
		border-radius: 24px;

		display: flex;
		flex-direction: column;
		gap: 24px;

		padding: 32px;
	}

	&__content
	{
		display: flex;
		flex-direction: column;
		gap: 24px;
	}

	&__title
	{
		font-family: $mainFontNew;
		font-weight: 700;
		font-size: 32px;
		line-height: 137%;

		color: $textColor;
	}

	&__info
	{
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	&__info-block
	{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 44px;
	}

	&__info-text
	{
		font-weight: 700;
		font-size: 24px;
		line-height: 133%;

		color: $green;
	}

	&__info-subtitle
	{
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;

		color: $greyLightText;
	}

	&__btn
	{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 240px;
		height: 56px;
	}
}

@media (max-width: 1369px)
{
	.order-confirmed
	{
		padding-top: 0;

		&__container
		{
			box-shadow: none;
			padding: 24px 32px;
		}
	}
}

@media (max-width: 767px)
{
	.order-confirmed
	{
		height: calc(100vh - 164px);

		&__container
		{justify-content: space-between;}

		&__title
		{
			font-size: 24px;
			line-height: 133%;
		}

		&__info
		{gap: 24px;}

		&__info-block
		{
			gap: normal;
			justify-content: space-between;
		}

		&__info-text
		{
			font-size: 16px;
			line-height: 150%;
		}

		&__btn
		{width: 100%;}
	}
}
</style>